import { Component, OnInit, OnDestroy, ViewChild, ElementRef, inject } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter, take } from "rxjs/operators";
import { urlBuilder } from "@zixi/shared-utils";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { TargetComponent } from "../target/target.component";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { RecoveryState, UserPermissions } from "../../../models/shared";
import { TargetsService } from "../targets.service";
import { AnyTarget, TargetApiType, TargetObjectType } from "../../channels/channel";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { TargetsDefinitions } from "../targets.definitions";

@Component({
    selector: "app-target-list",
    templateUrl: "./target-list.component.html"
})
export class TargetListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) target: RouterOutlet;
    //@ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;
    @ViewChild("leftContainer")
    set leftContainer(v: ElementRef | undefined) {
        const splitterWidth = localStorage.getItem("splitter-width");
        if (splitterWidth != null && v) v.nativeElement.style.flexBasis = splitterWidth;
    }

    refreshing = false;
    targets: AnyTarget[] = [];
    targetId: number | null;
    targetName: string | null;
    selectedRows: Array<AnyTarget> = [];
    userPermissions: UserPermissions;
    urls = Constants.urls;
    isResizing: boolean;
    showInsights = false;
    pageLoadCount = 0;
    targetsPaginationState: { page: number; totalPages: number } | null = null;
    firstLoad = false;

    private targetApiType: TargetApiType | null;
    private selectedTarget: AnyTarget | undefined;
    private tourSteps = TourSteps.targetInsights;
    private currentHttpIDs: number[];
    private currentRtmpIDs: number[];
    private currentPullIDs: number[];
    private currentPushIDs: number[];
    private currentUdpRtpIDs: number[];
    private currentRistIDs: number[];
    private currentSrtIDs: number[];
    private currentNdiIDs: number[];
    private currentCDIIDs: number[];
    private currentJPEGXSIDs: number[];
    private currentMediaLiveHttpIDs: number[];
    private currentEntitlementIDs: number[];
    private routeSubscription: Subscription;
    private targetsSubscription: Subscription;
    private targetsRefreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;

    splitterPosition = null;
    private targetsBS$ = new BehaviorSubject<AnyTarget[]>([]);
    multiSelectDisasterRecovery = false;

    private router = inject(Router);
    private targetsService = inject(TargetsService);
    private targetsDefinitions = inject(TargetsDefinitions);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private userService = inject(UsersService);
    private translate = inject(TranslateService);
    public mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    public tourService = inject(TourService);
    private ngbModal = inject(NgbModal);

    tableColumnsSchema = this.targetsDefinitions.getTableColumnsSchema();

    constructor() {
        // Set Title
        this.titleService.setTitle("TARGETS", "");

        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                this.selectedTarget = undefined;

                if (event.snapshot.params && event.snapshot.params.targetId && event.snapshot.params.type) {
                    this.targetName = event.snapshot.params.name;
                    this.targetId = urlBuilder.decode(event.snapshot.params.targetId);

                    const targetType = event.snapshot.params.type;

                    switch (targetType) {
                        case Constants.urls.targetTypes.http:
                            this.targetApiType = TargetApiType.Http;
                            break;
                        case Constants.urls.targetTypes.pull:
                            this.targetApiType = TargetApiType.Pull;
                            break;
                        case Constants.urls.targetTypes.push:
                            this.targetApiType = TargetApiType.Push;
                            break;
                        case Constants.urls.targetTypes.udp_rtp:
                            this.targetApiType = TargetApiType.UdpRtp;
                            break;
                        case Constants.urls.targetTypes.rtmp:
                            this.targetApiType = TargetApiType.Rtmp;
                            break;
                        case Constants.urls.targetTypes.rist:
                            this.targetApiType = TargetApiType.Rist;
                            break;
                        case Constants.urls.targetTypes.srt:
                            this.targetApiType = TargetApiType.Srt;
                            break;
                        case Constants.urls.targetTypes.ndi:
                            this.targetApiType = TargetApiType.Ndi;
                            break;
                        case Constants.urls.targetTypes.cdi:
                            this.targetApiType = TargetApiType.CDI;
                            break;
                        case Constants.urls.targetTypes.jpegxs:
                            this.targetApiType = TargetApiType.JPEGXS;
                            break;
                        case Constants.urls.targetTypes.medialive_http:
                            this.targetApiType = TargetApiType.MediaLiveHttp;
                            break;
                        case Constants.urls.targetTypes.entitlement:
                            this.targetApiType = TargetApiType.Entitlement;
                            break;
                        default:
                            this.targetId = null;
                            this.targetApiType = null;
                            this.targetName = null;
                            break;
                    }

                    this.updateSelectedTarget();
                } else {
                    this.targetId = null;
                    this.targetName = null;
                    this.targetApiType = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // local storage
        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position"), 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.targetsService.targetsPaginationSubject$.subscribe(pagination => {
            this.targetsPaginationState = pagination;
            if (this.pageLoadCount < pagination.totalPages) this.pageLoadCount++;
        });

        this.targetsSubscription = this.targetsService.targets.subscribe(targets => {
            this.targets = targets;
            if (this.targets) {
                this.firstLoad = true;
                this.selectedTarget = this.targets.find(
                    (t: AnyTarget) => t.objId === this.targetId && t.apiType === this.targetApiType
                );
                if (this.selectedTarget) this.selectedRows = [this.selectedTarget];

                this.prepTableData();
            }
        });
        // Start Auto Refresh
        this.startTargetsRefresh();

        this.targetsService.loadTargetsPaginated();

        setTimeout(() => {
            if (this.targetName) {
                this.updateSelectedTarget();
            } else {
                this.selectedRows = [];
            }
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.targetsSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopTargetsRefresh();
    }

    private updateSelectedTarget() {
        this.selectedTarget = this.targets.find(
            (t: AnyTarget) => t.objId === this.targetId && t.apiType === this.targetApiType
        );
        if (this.selectedTarget) {
            this.selectedRows = [this.selectedTarget];
            this.showInsights = false;
        }
    }

    resizing(v: boolean) {
        this.isResizing = v;
    }

    selectRow = (target: AnyTarget) => {
        this.showInsights = false;
        this.selectedTarget = target;
        this.router.navigate(urlBuilder.getRegularTargetUrl(target.objId, target.apiType, target.target.name));
    };

    onSort(s: string) {
        this.targetsDefinitions.currentTableSortDirection = s;
    }

    onPagination(targets: AnyTarget[]) {
        this.currentHttpIDs = targets.filter(t => t.apiType === TargetApiType.Http).map(target => target.objId);
        this.currentRtmpIDs = targets.filter(t => t.apiType === TargetApiType.Rtmp).map(target => target.objId);
        this.currentPullIDs = targets.filter(t => t.apiType === TargetApiType.Pull).map(target => target.objId);
        this.currentPushIDs = targets.filter(t => t.apiType === TargetApiType.Push).map(target => target.objId);
        this.currentUdpRtpIDs = targets.filter(t => t.apiType === TargetApiType.UdpRtp).map(target => target.objId);
        this.currentRistIDs = targets.filter(t => t.apiType === TargetApiType.Rist).map(target => target.objId);
        this.currentSrtIDs = targets.filter(t => t.apiType === TargetApiType.Srt).map(target => target.objId);
        this.currentNdiIDs = targets.filter(t => t.apiType === TargetApiType.Ndi).map(target => target.objId);
        this.currentCDIIDs = targets.filter(t => t.apiType === TargetApiType.CDI).map(target => target.objId);
        this.currentJPEGXSIDs = targets.filter(t => t.apiType === TargetApiType.JPEGXS).map(target => target.objId);
        this.currentMediaLiveHttpIDs = targets
            .filter(t => t.apiType === TargetApiType.MediaLiveHttp)
            .map(target => target.objId);
        this.currentEntitlementIDs = targets
            .filter(t => t.apiType === TargetApiType.Entitlement)
            .map(target => target.objId);
    }

    async refresh() {
        this.refreshing = true;

        const httpTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Http, this.currentHttpIDs)
            .toPromise();
        const rtmpTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Rtmp, this.currentRtmpIDs)
            .toPromise();
        const pullTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Pull, this.currentPullIDs)
            .toPromise();
        const pushTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Push, this.currentPushIDs)
            .toPromise();
        const udprtpTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.UdpRtp, this.currentUdpRtpIDs)
            .toPromise();
        const ristTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Rist, this.currentRistIDs)
            .toPromise();
        const srtTargetsRefresh = this.targetsService.refreshTargets(TargetApiType.Srt, this.currentSrtIDs).toPromise();
        const ndiTargetsRefresh = this.targetsService.refreshTargets(TargetApiType.Ndi, this.currentNdiIDs).toPromise();
        const cdiTargetsRefresh = this.targetsService.refreshTargets(TargetApiType.CDI, this.currentCDIIDs).toPromise();
        const jpegxsTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.JPEGXS, this.currentJPEGXSIDs)
            .toPromise();
        const mediaLiveHttpTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.MediaLiveHttp, this.currentMediaLiveHttpIDs)
            .toPromise();
        const entitlementTargetsRefresh = this.targetsService
            .refreshTargets(TargetApiType.Entitlement, this.currentEntitlementIDs)
            .toPromise();

        const targetRefresh =
            this.targetName && this.targetApiType && this.selectedTarget
                ? this.targetsService
                      .refreshTarget(this.selectedTarget.apiType, this.selectedTarget.objId, true)
                      .toPromise()
                : Promise.resolve([] as TargetObjectType[]);
        const targetComponentRefresh = new Promise(resolve => {
            if (this.target && this.target.component) {
                (this.target.component as TargetComponent).refresh();
            }
            resolve([] as TargetObjectType[]);
        });

        await Promise.all([
            httpTargetsRefresh,
            rtmpTargetsRefresh,
            pullTargetsRefresh,
            pushTargetsRefresh,
            udprtpTargetsRefresh,
            ristTargetsRefresh,
            srtTargetsRefresh,
            ndiTargetsRefresh,
            cdiTargetsRefresh,
            jpegxsTargetsRefresh,
            mediaLiveHttpTargetsRefresh,
            entitlementTargetsRefresh,
            targetRefresh,
            targetComponentRefresh
        ]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (target: AnyTarget) => Promise<unknown>, options?) {
        const result = await this.modalService.confirmMultiple(action, "TARGET", this.selectedRows, func, options);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple targets");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    async switchChannel() {
        const result = await this.modalService.switchChannel(
            this.selectedRows,
            this.selectedRows[0].adaptive ? "adaptive" : this.selectedRows[0].medialive ? "medialive" : "delivery"
        );
        if (result) {
            this.mixpanelService.sendEvent("switch channel multiple targets");
            this.refresh();
        }
    }

    canSwitchChannel() {
        if (this.selectedRows.length === 0) return false;

        const multiTypes = _.countBy(this.selectedRows, (anyTarget: AnyTarget) => {
            return anyTarget.adaptive ? "adaptive" : "delivery";
        });

        const hasCDIorJPEGXS = this.selectedRows.find(t => t.cdi || t.jpegxs);
        return (!multiTypes.adaptive || !multiTypes.delivery) && !hasCDIorJPEGXS;
    }

    multiDelete() {
        this.multiAction("DELETE", async (target: AnyTarget) => this.targetsService.deleteTarget(target.target), {
            warning: _.some(this.selectedRows, t => t.dynamic) ? "DYNAMIC_PULL_MULTI_DELETE_WARNING" : null
        });
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (target: AnyTarget) =>
            this.targetsService.updateTarget(target.target, { is_enabled: enable })
        );
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (target: AnyTarget) =>
            this.targetsService.updateTarget(target.target, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple("TARGET", this.selectedRows, async (target: AnyTarget, model) => {
            return this.targetsService.updateTarget(target.target, model);
        });
    }

    startTargetsRefresh() {
        this.targetsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopTargetsRefresh() {
        this.targetsRefreshSubscription.unsubscribe();
    }

    private prepTableData() {
        if (this.targets) {
            const targets = [...this.targets];
            this.targetsBS$.next(targets);
        }
    }

    get allSourcesObservable() {
        return this.targetsBS$;
    }

    toggleInsights() {
        this.showInsights = !this.showInsights;
        if (this.showInsights) {
            this.mixpanelService.sendEvent("view target insights", { ids: this.selectedRows.map(row => row.id) });
        }
    }

    onSelectedRowsChange(selectedRows: AnyTarget[]) {
        this.multiSelectDisasterRecovery = false;

        const isSelectionDRable = selectedRows.every(
            row => this.targetsService.getDisasterRecoveryState(row.target) !== RecoveryState.none
        );

        this.multiSelectDisasterRecovery = !!isSelectionDRable;
    }

    disasterRecoveryClick() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = this.selectedRows.map(t => ({
            id: t.target.id,
            type: t.type
        }));
    }
}
