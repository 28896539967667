import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants/constants";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { ZxHistoryGraphComponent } from "src/app/components/shared/zx-history-graph/zx-history-graph.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { GraphsService } from "src/app/services/graphs.service";
import { BaseLayout } from "src/app/helpers/base-layout";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { ReactFlowDiagramComponent } from "src/app/components/shared/react-flow-diagram/react-flow-diagram.component";
import { AnyTarget, TargetObjectType } from "../../channels/channel";
import { TargetDetailsComponent } from "./target-details/target-details.component";
import { TargetStreamComponent } from "./target-stream/target-stream.component";
import { ZxObjectTrackingMainListComponent } from "src/app/components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ObjectIncidentListComponent } from "src/app/components/shared/object-incidents/object-incident-list.component";
import { TargetTranscodingProfilesComponent } from "./target-transcoding-profiles/target-transcoding-profiles.component";
import { TargetChildrenComponent } from "./target-children/target-children.component";
import { TargetBondedLinksComponent } from "./target-bonded-links/target-bonded-links.component";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Source } from "src/app/models/shared";

export abstract class TargetLayouts extends BaseLayout {
    keys = {
        primaryDetails: [
            //The order is important for the layout
            "TARGET_THUMBNAIL",
            "ACTIVE_BROADCASTER",
            "BROADCASTER",
            "ZEC",
            "RECEIVER",
            "AWS_MEDIACONNECT_FLOW",
            "CHANNEL",
            "PRIORITY",
            "PLAY_HLS",
            "PLAY_TARGET",
            "TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "TYPE",
            "PARENT_TARGET",
            "TARGET",
            "SOURCE_PREFERENCE",
            "RECEIVER_NAME_ID",
            "USERNAME",
            "PASSWORD",
            "CLIENT_IP",
            "LOCATION",
            "NETWORK",
            "ALERTING_PROFILE",
            "TRANSCODING_PROFILE",
            "PID_MAPPING",
            "ENCRYPTION_KEY",
            "LATENCY",
            "BITRATE",
            "ENCAPSULATION",
            "AZURE_ACCOUNT",
            "GCP_ACCOUNT",
            "REGION",
            "ACCESS_KEY_ID",
            "AUTH_METHOD",
            "AUTH_USER",
            "APPLICATION",
            "DEVICE",
            "BUILD",
            "PORT",
            "LOCAL_NIC_CIDR",
            "TTL",
            "STREAM_ID",
            "STREAM_NAME",
            "ENCRYPTION",
            "PLAYBACK_URL",
            "TARGET_HOST",
            "ALT_TARGET_HOST",
            "SMPTE_2022_FEC",
            "SMOOTHING",
            "REMUX",
            "REMUX_MODE",
            "UPLOAD_DVR_PLAYLIST",
            "PROPAGATE_TAGS_TO_AWS",
            "DELETE_OUTDATED",
            "IGNORE_CERTIFICATE",
            "IGNORE_DTLS_CERTIFICATE",
            "ENFORCE_SUBTITLES",
            "ENTITLEMENT_ARN",
            "DATA_SUBSCRIBER_FEE",
            "SUBSCRIBER_ACCOUNT_ID",
            "S3_URI",
            "MEDIASTORE_URI",
            "HTTP_URI",
            "INGEST_URL",
            "SINGLE_OUTPUT",
            "PREFER_PRIVATE_BROADCASTER_IP",
            "HITLESS_FAILOVER_LATENCY",
            "USE_NAME_AS_STREAM_ID",
            "DISABLE_REDIRECTION",
            "BILLING_CREDENTIALS",
            "MTU",
            "EXTERNAL_ID",
            "POIS_LAT"
        ],
        nonDeletedSecondaryDetails: [
            "TYPE",
            "PARENT_TARGET",
            "TARGET",
            "SOURCE_PREFERENCE",
            "BITRATE",
            "LATENCY",
            "ENCAPSULATION",
            "REGION",
            "LOCATION",
            "NETWORK",
            "ALERTING_PROFILE"
        ],
        widgets: [
            //The order is important for the layout
            "DIAGRAM",
            "HISTORY",
            "STREAM",
            "CHILD_TARGETS",
            "BONDED_LINKS",
            "TRANSCODING_PROFILES",
            "DETAILS",
            "EVENTS",
            "CHANGES",
            "INCIDENTS",
            "NOTES"
        ]
    } as const;

    protected scopeName = "targetDetails";
    constants = Constants;
    abstract target: AnyTarget;
    abstract canEdit(target: AnyTarget): boolean;
    abstract preferredSource: Source;
    abstract targetDashboardLink: string;
    abstract tagType: string;
    abstract targetBackendType();
    // TODO maybe need to change it like this: something with .bind(this) fill weird to me...
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abstract refreshTargetPromise(): Promise<TargetObjectType>;
    constructor(
        protected translate: TranslateService,
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService
    ) {
        super(userService, sharedService, translate);
    }

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        )
    };

    protected primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected targetPrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected targetSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false,
        isExpanded: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    protected targetWidgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index: index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    protected targetWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: TargetDetailsComponent,
            inputs: {
                target: () => this.target,
                canEdit: () => this.canEdit(this.target),
                preferredSource: () => this.preferredSource
            }
        },
        {
            ...this.widgetsDefaultLayout.STREAM,
            component: TargetStreamComponent,
            inputs: {
                target: () => this.target
            }
        },
        {
            ...this.widgetsDefaultLayout.TRANSCODING_PROFILES,
            component: TargetTranscodingProfilesComponent,
            inputs: {
                target: () => this.target,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.DIAGRAM,
            component: ReactFlowDiagramComponent,
            inputs: {
                model: () => this.target.target,
                type: () => "target"
            }
        },
        {
            ...this.widgetsDefaultLayout.HISTORY,
            component: ZxHistoryGraphComponent,
            inputs: {
                object: () => ({ target: this.target.target }),
                objectType: () => this.targetBackendType(),
                graphUrl: () => this.targetDashboardLink
            }
        },
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => ({
                    [this.tagType]: this.target.pull ? this.pullTargetWithChildren() : this.target.target
                }),
                id: () => this.target.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.target.target,
                type: () => this.tagType,
                id: () => this.target.target.id,
                canEdit: () => this.canEdit(this.target)
            }
        },
        {
            ...this.widgetsDefaultLayout.CHANGES,
            component: ZxObjectTrackingMainListComponent,
            inputs: {
                objectId: () => this.target.target.id,
                objectName: () => this.target.target.name,
                objectType: () => this.targetBackendType(),
                objectApiType: () => this.objectTrackingApiType(),
                autoRows: () => false,
                refreshObjectDataFunction: () => this.refreshTargetPromise.bind(this)
            }
        },
        {
            ...this.widgetsDefaultLayout.INCIDENTS,
            component: ObjectIncidentListComponent,
            inputs: {
                objectId: () => this.target.target.id,
                objectType: () => this.targetBackendType(),
                bordered: () => false,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.CHILD_TARGETS,
            component: TargetChildrenComponent,
            inputs: {
                targets: () => this.target.target.childZixiPulls
            }
        },
        {
            ...this.widgetsDefaultLayout.BONDED_LINKS,
            component: TargetBondedLinksComponent,
            inputs: {
                target: () => this.target.target
            }
        }
    ];

    objectTrackingApiType() {
        return `targets/${this.target.apiType}`;
    }

    pullTargetWithChildren() {
        return [this.target.target, ...(this.target.target.childZixiPulls || [])];
    }

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): IconProp {
        switch (key) {
            case "DETAILS":
                return "align-justify";
            case "STREAM":
                return "clapperboard-play";
            case "DIAGRAM":
                return "sitemap";
            case "HISTORY":
                return "chart-line";
            case "EVENTS":
                return "exclamation-triangle";
            case "CHANGES":
                return "clock-rotate-left";
            case "NOTES":
                return ["far", "sticky-note"];
            case "INCIDENTS":
                return "file-exclamation";
            case "TRANSCODING_PROFILES":
                return "magic";
            case "CHILD_TARGETS":
                return "server";
            case "BONDED_LINKS":
                return "link";
            default:
                throw new Error("Unknown title"); //TODO should it throw error or return empty string?
        }
    }
}
