<content-loading-animation *ngIf="loadingDetails && !target"></content-loading-animation>

<!-- New -->
<div class="details-panel new-page" *ngIf="target && !loadingDetails">
    <!-- Header -->
    <app-details-page-header [tags]="target.target.resourceTags" [canEdit]="canEdit(target)" [canDisable]="canEdit(target)" (closePage)="cancel()">
        <zx-target title [model]="target" [showStatusIcon]="true" [showStatusText]="false" [showLink]="false" [showTag]="false"></zx-target>
        <app-details-page-header-action-buttons
            action-buttons
            [canEdit]="canEdit(target) && target.target.zixi_pull_parent_id == null"
            [canEnable]="canEdit(target)"
            [isEnabled]="!!target.target.is_enabled"
            [muted]="target.target.active_mute"
            (toggleMute)="toggleMute()"
            [editUrl]="editTarget(target.target.name)"
            [canToggle]="canEdit(target)"
            (toggle)="toggle()"
            (refresh)="refresh()"
            [refreshing]="isRefreshing"
        ></app-details-page-header-action-buttons>
        <app-details-page-header-drop-down
            drop-down
            [canEdit]="canEdit(target) && target.target.zixi_pull_parent_id == null"
            [canEnable]="canEdit(target)"
            [isEnabled]="!!target.target.is_enabled"
            [cloneBtn]="target.target.zixi_pull_parent_id == null"
            [userPermissions]="
                !resourceTags?.length || (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
            "
            (clone)="cloneTarget(target.target.name)"
            (delete)="deleteTarget()"
            (muteUntil)="muteUntil($event)"
            [editUrl]="editTarget(target.target.name)"
            [muted]="target.target.active_mute"
            (toggleMute)="toggleMute()"
            [canToggle]="canEdit(target)"
            (toggle)="toggle()"
            [altActionBtn]="recoveryState"
            (altAction)="goToDR()"
            [canSwitch]="canEdit(target)"
            [switchText]="
                target.target.zixi_pull_parent_id == null && !target.cdi && !target.jpegxs && !target.entitlement ? ('SWITCH_CHANNEL' | translate) : ''
            "
            (switch)="switchChannel()"
            [tracerouteBtn]="target.target.is_enabled && target.target.status && target.target.status?.trace && !target.dynamic"
            (traceroute)="traceroute()"
            [configBtn]="target.pull || (TargetsTypeGuard.isSrt(target.target) && !!target.target.pull_mode)"
            (config)="config()"
        ></app-details-page-header-drop-down>
    </app-details-page-header>
    <!-- Content -->
    <spinner-animation *ngIf="!isWidgetFullyLoaded"></spinner-animation>
    <div class="details-content" #scrollArea>
        <div class="scroll-content">
            <ng-container *ngIf="isWidgetFullyLoaded">
                <app-layout-buttons
                    *ngIf="!isMobile"
                    [savingLayout]="savingLayout"
                    (saveLayout)="saveLayout()"
                    (resetLayout)="resetLayout()"
                    (revertLayout)="revertLayoutChanges()"
                    [layoutChanged]="layoutChanged"
                    [isLocked]="isLocked"
                    (lockChanged)="lockChanged($event)"
                ></app-layout-buttons>
                <!-- Details-section -->
                <app-details-section
                    #primaryDetailsArea
                    [(detailsList)]="primaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isPrimaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isPrimaryDetails]="true"
                    [isLocked]="isLocked"
                    (refreshPage)="refresh()"
                    class="primary"
                    [ngClass]="{ sticky: isPrimaryDetailsSectionPinned }"
                ></app-details-section>
                <app-details-section
                    #secondaryDetailsArea
                    [(detailsList)]="secondaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isSecondaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isLocked]="isLocked"
                    [(isSectionHidden)]="isSecondaryDetailsSectionHidden"
                    (isSectionHiddenChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [ngClass]="{
                        sticky: isSecondaryDetailsSectionPinned || (isSecondaryDetailsSectionHidden && (isPrimaryDetailsSectionPinned || isWidgetHeaderPinned)),
                        hidden: isSecondaryDetailsSectionHidden
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && (isSecondaryDetailsSectionPinned || isSecondaryDetailsSectionHidden)
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionHidden
                                ? 40
                                : -1,
                        'z-index': constants.zindexValues.second
                    }"
                >
                </app-details-section>
                <!-- Alerts -->
                <div *ngIf="!target.dynamic">
                    <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="target.target.active_flapping && target.target.is_enabled">
                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                        <zx-date-time-zone [dateTime]="target.target.flapping" />
                    </ngb-alert>
                    <zx-active-states
                        [activeStates]="target.target.activeStates"
                        [refreshFunction]="refreshTarget"
                        [canEdit]="canEdit(target)"
                        [objectName]="target.target.name"
                    ></zx-active-states>
                </div>
                <!-- Tabs -->
                <app-widget-section-header
                    [(widgetHeaders)]="widgetHeaders"
                    (widgetSelectedChange)="onWidgetSelectChange($event)"
                    [(isSectionPinned)]="isWidgetHeaderPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isMultiSelect)]="isMultiSelect"
                    (isMultiSelectChange)="multiSelectChanged($event)"
                    [multiWidgetHeaders]="multiWidgetHeaders"
                    [isLocked]="isLocked"
                    [isMobile]="isMobile"
                    [ngClass]="{
                        sticky: isWidgetHeaderPinned
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight + secondaryDetailsAreaHeight - 1
                                : isPrimaryDetailsSectionPinned && !isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? secondaryDetailsAreaHeight - 1
                                : -1,
                        'z-index': constants.zindexValues.first
                    }"
                >
                </app-widget-section-header>
                <!-- Content -->
                <app-widget-section
                    [(widgets)]="widgets"
                    (widgetsChange)="onWidgetsLayoutChange($event)"
                    [isMultiSelect]="isMultiSelect"
                    [isLocked]="isLocked"
                ></app-widget-section>
            </ng-container>
        </div>
    </div>
</div>
