<content-loading-animation *ngIf="!firstLoad"></content-loading-animation>
<div class="panel-container" id="panel-container" *ngIf="firstLoad">
    <div
        id="left-container"
        class="left-container new-left-container"
        #leftContainer
        [ngClass]="{
            rowSelected: targetName || showInsights,
            leftSideContracted: splitterPosition === 1 && (targetName || showInsights),
            resizing: isResizing
        }"
    >
        <!-- Title Bar -->
        <div class="title-bar pe-2">
            <div class="title">
                <h1 translate title="{{ 'TARGETS' | translate }}">
                    TARGETS
                    <small *ngIf="targetsPaginationState && pageLoadCount < targetsPaginationState.totalPages">
                        {{ "LOADING" | translate }} ({{ pageLoadCount }} {{ "OF" | translate }} {{ targetsPaginationState.totalPages }})
                    </small>
                </h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        zmid="add-service"
                        type="button"
                        class="btn border-0 btn-outline-primary"
                        routerLink="/{{ urls.targets }}/new"
                        title="{{ 'ADD' | translate }}"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!targetName && !showInsights">{{ "ADD" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn border-0 btn-outline-secondary"
                        *ngIf="!(targetName || showInsights) || splitterPosition === 3"
                        (click)="refresh()"
                        title="{{ 'REFRESH' | translate }}"
                    >
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!targetName && !showInsights">
                            {{ "REFRESH" | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel pb-0" #listPanel [ngClass]="{ rowSelected: targetName || showInsights }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'target'"
                    [displayTableName]="'TARGETS' | translate"
                    [data]="allSourcesObservable | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (currentPage)="onPagination($event)"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [excelReportTitle]="'TARGETS' | translate"
                    [forceCollapseWithSelection]="showInsights"
                    (selectedRowsChange)="onSelectedRowsChange($event)"
                >
                    <div class="d-flex grid-gap-2" *ngIf="!targetName && !showInsights && selectedRows.length > 0">
                        <button
                            type="button"
                            class="btn btn-outline-green"
                            [ngClass]="{ active: showInsights }"
                            (click)="toggleInsights()"
                            title="{{ 'INSIGHTS' | translate }}"
                        >
                            <fa-icon icon="chart-scatter" size="md"></fa-icon>
                            <span class="d-none d-sm-inline-block ms-1">{{ "INSIGHTS" | translate }}</span>
                        </button>
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMute($event)"
                            (multiEdit)="multiEdit()"
                            (multiEnable)="multiToggleState(true)"
                            (multiToggleState)="multiToggleState($event)"
                            (multiDelete)="multiDelete()"
                            (switchChannel)="switchChannel()"
                            [switchChannelButton]="true"
                            [canSwitchChannel]="canSwitchChannel()"
                            [disasterRecovery]="multiSelectDisasterRecovery"
                            (disasterRecoveryClick)="disasterRecoveryClick()"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-splitter *ngIf="targetName || showInsights" (resizing)="resizing($event)"></zx-splitter>
    <div class="new-right-container" [ngClass]="{ resizing: isResizing, rightSideContracted: splitterPosition === 3 }" *ngIf="targetName || showInsights">
        <router-outlet *ngIf="targetName"></router-outlet>

        <div class="insights-wrapper" *ngIf="showInsights">
            <div class="details-panel">
                <div class="details-action-bar minh-52px">
                    <div class="details-title">
                        <h2 class="ellipsis" title="{{ 'INSIGHTS' | translate }}" tourAnchor="firstTargetInsightsAnchor">
                            <button type="button" class="btn btn-round me-1" (click)="toggleInsights()" title="{{ 'BACK' | translate }}">
                                <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                                <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                            >{{ "INSIGHTS" | translate
                            }}<fa-icon
                                class="ms-2 tourStartButton"
                                icon="question-circle"
                                #tooltip="matTooltip"
                                matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_INSIGHTS_BY_CLICKING_HERE' | translate }}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                size="sm"
                                (click)="tourService.start(); mixpanelService.sendEvent('start target insights feature guide')"
                            ></fa-icon>
                        </h2>
                    </div>
                </div>
                <div class="details-content-tabs p-3" tourAnchor="secondTargetInsightsAnchor">
                    <app-list-insights
                        tourAnchor="thirdTargetInsightsAnchor"
                        [targets]="selectedRows"
                        [panels]="[
                            'rist-target-network-bitrate',
                            'rist-target-network-not-recovered',
                            'rtmp_push-target-network-bitrate',
                            'rtmp_push-target-network-send-errors',
                            'srt_targets-target-network-bitrate',
                            'srt_targets-target-network-not-recovered',
                            'udp_rtp-target-network-bitrate',
                            'udp_rtp-target-network-send-errors',
                            'zixi_pull-target-bitrate',
                            'zixi_pull-target-network-rtt',
                            'zixi_pull-target-network-jitter',
                            'zixi_pull-target-network-recovered',
                            'zixi_pull-target-network-not-recovered',
                            'zixi_pull-target-network-reconnections',
                            'zixi_pull-target-network-send-errors',
                            'zixi_push-target-bitrate',
                            'zixi_push-target-network-rtt',
                            'zixi_push-target-network-jitter',
                            'zixi_push-target-network-recovered',
                            'zixi_push-target-network-not-recovered',
                            'zixi_push-target-network-reconnections',
                            'zixi_push-target-network-send-errors'
                        ]"
                    ></app-list-insights>
                </div>
            </div>
        </div>
    </div>
</div>
