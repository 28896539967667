import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    inject,
    HostListener,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { TargetsService } from "../targets.service";
import { AnyTarget, TargetApiType, ZixiPushTarget } from "../../channels/channel";
import { Constants } from "../../../constants/constants";
import { RecoveryState, Source, Tag, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

// Components
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { GraphsService } from "src/app/services/graphs.service";
import { urlBuilder } from "@zixi/shared-utils";
import { OBJECT_TYPE } from "../../networks/network-objects-state/network-objects-state.service";
import { FormControl } from "@angular/forms";
import { ResizeService } from "src/app/services/resize.service";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { TargetLayouts } from "./target.layout";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { TagsService } from "../../configuration/tags/tags.service";
import { TargetsTypeGuard } from "src/app/utils/type-guards/targets-type-guard";
import { SourcesService } from "../../sources/sources.service";

@Component({
    selector: "app-target",
    templateUrl: "./target.component.html"
})
export class TargetComponent extends TargetLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    target: AnyTarget;
    targetId: number;
    targetName: string | null;
    targetApiType: TargetApiType;
    preferredSource: Source;

    RecoveryState = RecoveryState;
    recoveryState: RecoveryState;

    resourceTags: Tag[];
    tagType: string;
    targetDashboardLink: string;
    userPermissions: UserPermissions;

    activeTab: string;
    loadingDetails = true;
    showOverlayControl = new FormControl(false);
    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    widgetsToRemoveOrAdd: { title: string; toHide: boolean }[] = [];
    isRefreshing = false;

    private targetsSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;
    private refreshThumbnailSubscription: Subscription;

    primaryDetails = this.targetPrimaryDetails;
    secondaryDetails = this.targetSecondaryDetails;
    widgets = this.targetWidgets;
    widgetHeaders = this.targetWidgetHeaders;

    TargetsTypeGuard = TargetsTypeGuard;

    // TODO: need to handle different target types
    protected updateDetailContent(title: string) {
        switch (title) {
            // Primary
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.target.target,
                    tags: this.target.target.resourceTags,
                    type: "target",
                    canEdit: () => this.canEdit(this.target)
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.tagsService.isObjectVip(this.target.target.resourceTags) ? "Yes" : "No"
                };
            case this.translatedNames.TARGET_THUMBNAIL:
                return {
                    content: this.target ? "-" : "",
                    object: this.target.target,
                    isThumbnail: true
                };
            case this.translatedNames.PLAY_HLS:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) &&
                        !this.target.target.playback_url &&
                        (this.target.target.type === "s3" ||
                            this.target.target.type === "mediastore" ||
                            this.target.target.type === "azure")
                            ? "-"
                            : "",
                    object: this.target
                };
            case this.translatedNames.PLAY_TARGET:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target.playback_url ? "-" : "",
                    object: this.target
                };
            // Secondary
            case this.translatedNames.PARENT_TARGET:
                return {
                    content: this.target.target?.parentZixiPull ? this.target.target?.parentZixiPull?.name : ""
                };
            case this.translatedNames.TYPE:
                return {
                    content: this.translate.instant(this.target.type_name.toUpperCase())
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.target.target.alertingProfile?.name ?? "",
                    link:
                        "/" +
                        this.constants.urls.configuration.eventsManagement +
                        "/" +
                        this.target.target.alertingProfile?.id
                };
            case this.translatedNames.LOCATION:
                return {
                    content: (this.target.target.location?.ip || this.target.target.location?.address)?.display_name
                        ? "-"
                        : "",
                    object: this.target.target
                };
            case this.translatedNames.NETWORK:
                return {
                    content: this.target.target.location?.ip
                        ? this.sharedService.getNetwork(this.target.target.location)
                        : "",
                    maxWidth: 240
                };
            //
            case this.translatedNames.ACTIVE_BROADCASTER:
                return {
                    content:
                        this.target._frontData?.active_broadcaster && this.target._frontData?.active_broadcaster.id
                            ? "-"
                            : "",
                    object: this.target._frontData?.active_broadcaster,
                    cluster_id:
                        this.target.target.deliveryChannel?.broadcaster_cluster_id ||
                        this.target.target?.activeBroadcasterObjects?.cluster_id
                };
            case this.translatedNames.AWS_MEDIACONNECT_FLOW:
                return {
                    content: this.target.target.mediaconnect_flow_id ? "-" : "",
                    object: this.target.target.mediaconnectFlow
                };
            case this.translatedNames.ZEC:
                return {
                    content: TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.zec_id ? "-" : "",
                    object: TargetsTypeGuard.isZixiPull(this.target.target) ? this.target.target.zec : ""
                };
            case this.translatedNames.RECEIVER:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.receiver_id ? "-" : "",
                    object: TargetsTypeGuard.isZixiPull(this.target.target) ? this.target.target.receiver : "",
                    output:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.output_name
                            ? this.target.target.output_name
                            : ""
                };
            case this.translatedNames.BROADCASTER:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.broadcaster_id ? "-" : "",
                    object: TargetsTypeGuard.isZixiPull(this.target.target) ? this.target.target.broadcaster : "",
                    output:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.output_name
                            ? this.target.target.output_name
                            : ""
                };
            case this.translatedNames.CHANNEL:
                return {
                    content:
                        (this.target.adaptive && this.target.target.adaptive_channel_id !== null) ||
                        ((this.target.pull || this.target.srt || this.target.push) &&
                            this.target.target.delivery_channel_id !== null &&
                            this.target.target.mediaconnect_flow_id == null) ||
                        ((this.target.udp_rtp || this.target.rist || this.target.ndi) &&
                            this.target.target.delivery_channel_id !== null) ||
                        ((this.target.rtmp || this.target.medialive_http) &&
                            this.target.target.medialive_channel_id == null &&
                            this.target.target.delivery_channel_id !== null)
                            ? "-"
                            : "",
                    object:
                        this.target.adaptive && this.target.target.adaptive_channel_id !== null
                            ? this.target.target.adaptiveChannel
                            : ((this.target.pull || this.target.srt || this.target.push) &&
                                  this.target.target.delivery_channel_id !== null &&
                                  this.target.target.mediaconnect_flow_id == null) ||
                              ((this.target.udp_rtp || this.target.rist || this.target.ndi) &&
                                  this.target.target.delivery_channel_id !== null) ||
                              ((this.target.rtmp || this.target.medialive_http) &&
                                  this.target.target.medialive_channel_id == null &&
                                  this.target.target.delivery_channel_id != null)
                            ? this.target.target.deliveryChannel
                            : ""
                };
            //
            case this.translatedNames.PID_MAPPING:
                return {
                    content:
                        TargetsTypeGuard.hasPidMapping(this.target.target) && this.target.target.pid_mapping?.name
                            ? this.target.target.pid_mapping?.name
                            : "",
                    link: TargetsTypeGuard.hasPidMapping(this.target.target)
                        ? "/" +
                          Constants.urls.transformation.pid_mapping_profiles +
                          "/" +
                          this.target.target.pid_mapping?.name
                        : ""
                };
            case this.translatedNames.ENCAPSULATION:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target.encapsulation
                            ? this.translate.instant(this.target.target.encapsulation.toUpperCase())
                            : ""
                };
            case this.translatedNames.AZURE_ACCOUNT:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target.azure_account_id
                            ? this.target.target.azureAccount.name
                            : ""
                };
            case this.translatedNames.GCP_ACCOUNT:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target.gcp_account_id
                            ? this.target.target.gcpAccount.name
                            : ""
                };
            case this.translatedNames.REGION:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) &&
                        (this.target.target.type === "s3" || this.target.target.type === "mediastore")
                            ? this.target.target.aws_region
                            : ""
                };
            case this.translatedNames.ACCESS_KEY_ID:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) &&
                        (this.target.target.type === "s3" || this.target.target.type === "mediastore")
                            ? this.target.target.aws_access_key_id
                            : ""
                };
            case this.translatedNames.AUTH_METHOD:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target?.auth_method
                            ? this.translate.instant(this.target.target.auth_method.toUpperCase())
                            : ""
                };
            case this.translatedNames.AUTH_USER:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) && this.target.target?.auth_user_name
                            ? this.target.target.auth_user_name
                            : ""
                };
            case this.translatedNames.PLAYBACK_URL:
                return {
                    content:
                        TargetsTypeGuard.isPublishing(this.target.target) &&
                        this.target.target.playback_url &&
                        this.target.target.playback_url !== ""
                            ? this.target.target.playback_url
                            : ""
                };
            case this.translatedNames.UPLOAD_DVR_PLAYLIST:
                return {
                    content: !TargetsTypeGuard.isPublishing(this.target.target)
                        ? ""
                        : this.target.target.record_dvr
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.PROPAGATE_TAGS_TO_AWS:
                return {
                    content: !TargetsTypeGuard.isPublishing(this.target.target)
                        ? ""
                        : this.target.target.propagate_tags
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.DELETE_OUTDATED:
                return {
                    content: !TargetsTypeGuard.isPublishing(this.target.target)
                        ? ""
                        : this.target.target.delete_outdated
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.IGNORE_CERTIFICATE:
                return {
                    content: !TargetsTypeGuard.isPublishing(this.target.target)
                        ? ""
                        : this.target.target.ignore_certificate
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.ENFORCE_SUBTITLES:
                return {
                    content: !TargetsTypeGuard.isPublishing(this.target.target)
                        ? ""
                        : this.target.target.monitor_missing_subtitles
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.RECEIVER_NAME_ID:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.receiver_name
                            ? this.target.target.receiver_name
                            : ""
                };
            case this.translatedNames.CLIENT_IP:
                return {
                    content: this.target.pull && this.target.target.status?.ip ? this.target.target.status?.ip : ""
                };
            case this.translatedNames.PASSWORD:
                return {
                    content:
                        (TargetsTypeGuard.isZixiPull(this.target.target) ||
                            TargetsTypeGuard.isSrt(this.target.target) ||
                            TargetsTypeGuard.isZixiPush(this.target.target) ||
                            TargetsTypeGuard.isRtmpPush(this.target.target)) &&
                        this.target.target.password &&
                        this.canEdit(this.target)
                            ? this.target.target.password
                            : "",
                    password: true
                };
            case this.translatedNames.ENCRYPTION_KEY:
                return {
                    content:
                        (TargetsTypeGuard.isZixiPull(this.target.target) ||
                            TargetsTypeGuard.isZixiPush(this.target.target)) &&
                        this.target.target.zixi_encryption_key
                            ? this.target.target.zixi_encryption_key
                            : "",
                    password: true
                };
            case this.translatedNames.LATENCY:
                return {
                    content:
                        this.target.pull && !this.target.target.latency
                            ? this.translate.instant("REMOTE_CONFIGURATION")
                            : this.target.target.latency
                            ? this.target.target.latency
                            : "",
                    unit: this.target.target.latency ? "ms" : ""
                };
            case this.translatedNames.APPLICATION:
                return {
                    content: this.target.target?.status?.app ?? ""
                };
            case this.translatedNames.DEVICE:
                return {
                    content: this.target.target?.status?.device ?? ""
                };
            case this.translatedNames.BUILD:
                return {
                    content: this.target.target?.status?.build ?? ""
                };
            case this.translatedNames.PORT:
                return {
                    content:
                        TargetsTypeGuard.isSrt(this.target.target) && this.target.target.pull_mode !== 0
                            ? this.target.target.port
                            : ""
                };
            case this.translatedNames.LOCAL_NIC_CIDR:
                return {
                    content:
                        (TargetsTypeGuard.isSrt(this.target.target) ||
                            TargetsTypeGuard.isUdpRtp(this.target.target) ||
                            TargetsTypeGuard.isRist(this.target.target)) &&
                        this.target.target.bind_cidr
                            ? this.target.target.bind_cidr
                            : ""
                };
            case this.translatedNames.TTL:
                return {
                    content:
                        TargetsTypeGuard.isUdpRtp(this.target.target) && this.target.target.ttl
                            ? this.target.target.ttl
                            : ""
                };
            case this.translatedNames.TARGET:
                return {
                    content:
                        TargetsTypeGuard.isSrt(this.target.target) &&
                        this.target.target.pull_mode === 0 &&
                        this.target.target.host &&
                        this.target.target.port
                            ? this.target.target.host + ":" + this.target.target.port
                            : (TargetsTypeGuard.isUdpRtp(this.target.target) ||
                                  TargetsTypeGuard.isRist(this.target.target)) &&
                              this.target.target.host &&
                              this.target.target.port
                            ? this.target.target.host + ":" + this.target.target.port
                            : TargetsTypeGuard.isMediaconnectCDI(this.target.target) &&
                              this.target.target.destination_ip &&
                              this.target.target.destination_port
                            ? this.target.target.destination_ip + ":" + this.target.target.destination_port
                            : ""
                };
            case this.translatedNames.STREAM_ID:
                return {
                    content:
                        TargetsTypeGuard.isZixiPush(this.target.target) && this.target.target.stream_id
                            ? this.target.target.stream_id
                            : TargetsTypeGuard.isSrt(this.target.target) &&
                              this.target.target.pull_mode === 0 &&
                              this.target.target.srt_stream_id
                            ? this.target.target.srt_stream_id
                            : ""
                };
            case this.translatedNames.ENCRYPTION:
                return {
                    content:
                        TargetsTypeGuard.isSrt(this.target.target) && this.target.target.encryption
                            ? this.target.target.encryption
                            : TargetsTypeGuard.isSrt(this.target.target) && !this.target.target.encryption
                            ? this.translate.instant("DISABLED")
                            : ""
                };
            case this.translatedNames.TARGET_HOST:
                return {
                    content:
                        TargetsTypeGuard.isZixiPush(this.target.target) && this.target.target.target
                            ? this.target.target.target
                            : ""
                };
            case this.translatedNames.ALT_TARGET_HOST:
                return {
                    content:
                        TargetsTypeGuard.isZixiPush(this.target.target) && this.target.target.alt_target
                            ? this.target.target.alt_target
                            : ""
                };
            case this.translatedNames.IGNORE_DTLS_CERTIFICATE:
                return {
                    content:
                        TargetsTypeGuard.isZixiPush(this.target.target) && this.target.target.ignore_dtls_cert === 1
                            ? "Yes"
                            : TargetsTypeGuard.isZixiPush(this.target.target) &&
                              this.target.target.ignore_dtls_cert === 0
                            ? "No"
                            : ""
                };
            case this.translatedNames.SMPTE_2022_FEC:
                return {
                    content:
                        (TargetsTypeGuard.isUdpRtp(this.target.target) ||
                            TargetsTypeGuard.isRist(this.target.target)) &&
                        this.target.target.smpte_2022_fec > 0
                            ? this.target.target.smpte_2022_fec +
                              "D (" +
                              this.target.target.smpte_2022_fec_rows +
                              "x" +
                              this.target.target.smpte_2022_fec_cols +
                              ")"
                            : ""
                };
            case this.translatedNames.REMUX:
                return {
                    content:
                        (TargetsTypeGuard.isUdpRtp(this.target.target) ||
                            TargetsTypeGuard.isRist(this.target.target)) &&
                        this.target.target.remux_kbps
                            ? this.sharedService.getKBPSContent(this.target.target.remux_kbps)
                            : "",
                    unit: "kbps"
                };
            case this.translatedNames.SMOOTHING:
                return {
                    content:
                        (TargetsTypeGuard.isUdpRtp(this.target.target) ||
                            TargetsTypeGuard.isRist(this.target.target)) &&
                        this.target.target.smoothing
                            ? this.target.target.smoothing
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.REMUX_MODE:
                return {
                    content:
                        (TargetsTypeGuard.isUdpRtp(this.target.target) ||
                            TargetsTypeGuard.isRist(this.target.target)) &&
                        this.target.target.remux_mode
                            ? this.translate.instant(this.target.target.remux_mode.toUpperCase())
                            : ""
                };
            case this.translatedNames.TRANSCODING_PROFILE:
                return {
                    content:
                        TargetsTypeGuard.isRtmpPush(this.target.target) && this.target.target.transcodingProfile?.name
                            ? this.target.target.transcodingProfile?.name
                            : "",
                    link:
                        "/" +
                        Constants.urls.transformation.transcoding_profiles +
                        "/" +
                        this.target.target.transcodingProfile?.name
                };
            case this.translatedNames.STREAM_NAME:
                return {
                    content:
                        TargetsTypeGuard.isRtmpPush(this.target.target) && this.target.target.stream_name
                            ? this.target.target.stream_name
                            : ""
                };
            case this.translatedNames.USERNAME:
                return {
                    content:
                        TargetsTypeGuard.isRtmpPush(this.target.target) && this.target.target.username
                            ? this.target.target.username
                            : ""
                };
            case this.translatedNames.BITRATE:
                return {
                    content:
                        TargetsTypeGuard.isRtmpPush(this.target.target) && this.target.target.kbps
                            ? this.target.target.kbps
                            : TargetsTypeGuard.isRtmpPush(this.target.target) && !this.target.target.kbps
                            ? "Auto Detect"
                            : "",
                    unit: TargetsTypeGuard.isRtmpPush(this.target.target) && this.target.target.kbps ? "kbps" : ""
                };
            case this.translatedNames.ENTITLEMENT_ARN:
                return {
                    content:
                        TargetsTypeGuard.isMediaconnectEntitlement(this.target.target) &&
                        this.target.target.entitlement_arn
                            ? this.target.target.entitlement_arn
                            : ""
                };
            case this.translatedNames.S3_URI:
                return {
                    content:
                        (this.target.target.type === "s3" && TargetsTypeGuard.isPublishing(this.target.target)) ||
                        (TargetsTypeGuard.isMediaLiveHttp(this.target.target) &&
                            this.target.target.destination === "s3")
                            ? this.target.target.ingest_url
                            : ""
                };
            case this.translatedNames.MEDIASTORE_URI:
                return {
                    content:
                        (this.target.target.type === "mediastore" &&
                            TargetsTypeGuard.isPublishing(this.target.target)) ||
                        (TargetsTypeGuard.isMediaLiveHttp(this.target.target) &&
                            this.target.target.destination === "mediastore")
                            ? this.target.target.ingest_url
                            : ""
                };
            case this.translatedNames.HTTP_URI:
                return {
                    content:
                        TargetsTypeGuard.isMediaLiveHttp(this.target.target) &&
                        this.target.target.destination === "http"
                            ? this.target.target.ingest_url
                            : ""
                };
            case this.translatedNames.INGEST_URL:
                return {
                    content:
                        this.target.target.type !== "mediastore" &&
                        this.target.target.type !== "s3" &&
                        TargetsTypeGuard.isPublishing(this.target.target)
                            ? this.target.target.ingest_url
                            : ""
                };
            case this.translatedNames.DATA_SUBSCRIBER_FEE:
                return {
                    content: TargetsTypeGuard.isMediaconnectEntitlement(this.target.target)
                        ? this.target.target.subscriber_fee_precent
                        : "",
                    unit: "%"
                };
            case this.translatedNames.SUBSCRIBER_ACCOUNT_ID:
                return {
                    content:
                        TargetsTypeGuard.isMediaconnectEntitlement(this.target.target) &&
                        this.target.target.subscriber_id
                            ? this.target.target.subscriber_id
                            : ""
                };
            case this.translatedNames.SOURCE_PREFERENCE:
                return {
                    content:
                        this.target.target.preferred_source || this.target.target.preferred_source === 0 ? "-" : "",
                    object: this.target,
                    preferredSource: this.preferredSource
                };
            case this.translatedNames.SINGLE_OUTPUT:
                return {
                    content:
                        !TargetsTypeGuard.isSrt(this.target.target) && !TargetsTypeGuard.isRtmpPush(this.target.target)
                            ? ""
                            : this.target.target.single_output
                            ? this.translate.instant("ENABLED")
                            : this.translate.instant("DISABLED")
                };
            case this.translatedNames.PREFER_PRIVATE_BROADCASTER_IP:
                return {
                    content:
                        !(
                            TargetsTypeGuard.isSrt(this.target.target) &&
                            this.target.target.pull_mode === 1 &&
                            !this.target.target.mediaconnect_flow_id
                        ) && !TargetsTypeGuard.isZixiPull(this.target.target)
                            ? ""
                            : this.target.target.use_private_ip
                            ? this.translate.instant("ENABLED")
                            : this.translate.instant("DISABLED")
                };

            case this.translatedNames.HITLESS_FAILOVER_LATENCY:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.merge_window_ms
                            ? this.target.target.merge_window_ms
                            : "",
                    unit: "ms"
                };
            case this.translatedNames.USE_NAME_AS_STREAM_ID:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.stream_id
                            ? this.translate.instant("ENABLED")
                            : ""
                };
            case this.translatedNames.DISABLE_REDIRECTION:
                return {
                    content:
                        TargetsTypeGuard.isZixiPull(this.target.target) && this.target.target.disable_redirection
                            ? this.translate.instant("YES")
                            : ""
                };

            case this.translatedNames.BILLING_CREDENTIALS:
                return {
                    content:
                        TargetsTypeGuard.hasExternalID(this.target.target) && this.target.target.billing_code
                            ? this.target.target.billing_code
                            : "",
                    object: this.target,
                    copyCodeLabel: this.translate.instant("COPY_CODE"),
                    copyPasswordLabel: this.translate.instant("COPY_PASSWORD"),
                    password:
                        TargetsTypeGuard.hasExternalID(this.target.target) && this.target.target.billing_password
                            ? this.target.target.billing_password
                            : ""
                };
            case this.translatedNames.MTU:
                return {
                    content:
                        TargetsTypeGuard.hasMTU(this.target.target) && this.target.target.mtu
                            ? this.target.target.mtu
                            : ""
                };
            case this.translatedNames.EXTERNAL_ID:
                return {
                    content:
                        TargetsTypeGuard.hasExternalID(this.target.target) && this.target.target.external_id
                            ? this.target.target.external_id
                            : ""
                };
            case this.translatedNames.POIS_LAT:
                return {
                    content:
                        TargetsTypeGuard.hasPOISLatency(this.target.target) && this.target.target.pois_latency_offset
                            ? this.target.target.pois_latency_offset
                            : "",
                    unit: "ms"
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private ngbModal = inject(NgbModal);
    private navigationService = inject(NavigationService);
    private resizeService = inject(ResizeService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private tagsService = inject(TagsService);
    private targetsService = inject(TargetsService);
    private sourcesService = inject(SourcesService);

    constructor(
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService,
        protected translate: TranslateService
    ) {
        super(translate, graphsService, userService, sharedService);
        this.route.paramMap.subscribe(async params => {
            this.targetId = urlBuilder.decode(params.get("targetId") ?? "");
            this.targetName = params.get("name");
            const targetType = params.get("type");

            switch (targetType) {
                case Constants.urls.targetTypes.http:
                    this.targetApiType = TargetApiType.Http;
                    this.tagType = OBJECT_TYPE.HTTP_PUBLISHING_TARGET;
                    break;
                case Constants.urls.targetTypes.pull:
                    this.targetApiType = TargetApiType.Pull;
                    this.tagType = OBJECT_TYPE.ZIXI_PULL_TARGET;
                    break;
                case Constants.urls.targetTypes.push:
                    this.targetApiType = TargetApiType.Push;
                    this.tagType = OBJECT_TYPE.ZIXI_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.udp_rtp:
                    this.targetApiType = TargetApiType.UdpRtp;
                    this.tagType = OBJECT_TYPE.UDP_RTP_TARGET;
                    break;
                case Constants.urls.targetTypes.rtmp:
                    this.targetApiType = TargetApiType.Rtmp;
                    this.tagType = OBJECT_TYPE.RTMP_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.rist:
                    this.targetApiType = TargetApiType.Rist;
                    this.tagType = OBJECT_TYPE.RIST_TARGET;
                    break;
                case Constants.urls.targetTypes.srt:
                    this.targetApiType = TargetApiType.Srt;
                    this.tagType = OBJECT_TYPE.SRT_TARGET;
                    break;
                case Constants.urls.targetTypes.ndi:
                    this.targetApiType = TargetApiType.Ndi;
                    this.tagType = OBJECT_TYPE.NDI_TARGET;
                    break;
                case Constants.urls.targetTypes.cdi:
                    this.targetApiType = TargetApiType.CDI;
                    this.tagType = "mediaconnect_cdi_targets";
                    break;
                case Constants.urls.targetTypes.jpegxs:
                    this.targetApiType = TargetApiType.JPEGXS;
                    this.tagType = "mediaconnect_jpegxs_targets";
                    break;
                case Constants.urls.targetTypes.medialive_http:
                    this.targetApiType = TargetApiType.MediaLiveHttp;
                    this.tagType = "medialive_http_targets";
                    break;
                case Constants.urls.targetTypes.entitlement:
                    this.targetApiType = TargetApiType.Entitlement;
                    this.tagType = "mediaconnect_entitlement_targets";
                    break;
                case Constants.urls.targetTypes.mediaconnect:
                    this.targetApiType = TargetApiType.Mediaconnect;
                    this.tagType = "mediaconnect";
                    break;
                default:
                    return this.cancel();
            }

            // Resource Tags
            this.sharedService
                .getResourceTagsByType(this.tagType ? this.tagType : "resource")
                .pipe(take(1))
                .subscribe((tags: Tag[]) => {
                    this.resourceTags = tags;
                });

            if (this.targetId) {
                const target = this.targetsService.getCachedTarget(this.targetId, this.targetApiType);
                if (target) this.target = target;
            }
            if (!this.target) return this.cancel();

            // Set Title
            this.titleService.setTitle("TARGET", "", this.target.target.name);
            this.updateAllThings();
        });
    }

    updateAllThings() {
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [
            { title: this.translatedNames.STREAM, toHide: this.isTargetDown(this.target) },
            {
                title: this.translatedNames.HISTORY,
                toHide: !(
                    this.target.target.mediaconnect_flow_id == null &&
                    this.target.target.medialive_channel_id == null &&
                    !this.target.dynamic &&
                    this.target.type !== "cdi" &&
                    this.target.type !== "jpegxs" &&
                    !this.target.medialive_http
                )
            },
            { title: this.translatedNames.CHANGES, toHide: !this.canEdit(this.target) },
            { title: this.translatedNames.INCIDENTS, toHide: !this.targetsService.targetBackendType(this.target) },
            {
                title: this.translatedNames.TRANSCODING_PROFILES,
                toHide: !(this.target.target as any)?.transcodingProfiles?.length
            },
            { title: this.translatedNames.CHILD_TARGETS, toHide: !this.target.dynamic },
            {
                title: this.translatedNames.BONDED_LINKS,
                toHide:
                    !(this.target.target instanceof ZixiPushTarget) ||
                    !(this.target.target as ZixiPushTarget).bondedLinks?.length
            }
        ];
    }

    protected addOrRemoveFromDetails() {
        const thumbnailIndex = this.primaryDetails.findIndex(
            details => details.title === this.translatedNames.TARGET_THUMBNAIL
        );
        if (this.isTargetDown(this.target)) this.primaryDetails[thumbnailIndex].isHidden = true;
        else if (thumbnailIndex !== -1) this.primaryDetails[thumbnailIndex].isHidden = false;

        this.primaryDetails = [...this.primaryDetails];
    }

    async refresh() {
        this.isRefreshing = true;
        await firstValueFrom(this.targetsService.refreshTarget(this.targetApiType, this.target.objId, true));
        this.isRefreshing = false;
    }

    traceroute() {
        this.targetsService.targetTracerouteModal(this.target);
    }

    isTargetDown(target: AnyTarget) {
        if (!target.target?.status?.stats?.net_bitrate) return true;
        return false;
    }

    async getTargetPreferredSource() {
        if (this.target.target.preferred_source && this.target.target.preferred_source > 0) {
            this.preferredSource = await firstValueFrom(
                this.sourcesService.refreshSource(this.target.target.preferred_source)
            );
        }
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isTargetMultiselect"))
            this.isMultiSelect = localStorage.getItem("isTargetMultiselect") === "true" ? true : false;

        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        this.targetsSubscription = this.targetsService.targets.subscribe(async targets => {
            const target = targets.find(
                (t: AnyTarget) => t.target.name === this.targetName && t.apiType === this.targetApiType
            );
            if (target) this.target = target;

            if (this.target && this.target.target.hasFullDetails) {
                this.getTargetPreferredSource();
                this.targetDashboardLink = this.graphsService.custom(this.graphsService.target(this.target));
                this.updateAllThings();
                this.loadingDetails = false;
            }
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromWidgets();
        });

        // Start Thumbnail Refresh
        this.startThumbnailRefresh();

        // set initial layout
        await this.revertLayoutChanges();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
        this.recoveryState = this.getDisasterRecoveryState();
    }

    ngOnDestroy() {
        this.targetsSubscription?.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
        this.stopThumbnailRefresh();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    startThumbnailRefresh() {
        this.refreshThumbnailSubscription = interval(8000).subscribe(() => {
            this.refreshThumbnail();
        });
    }

    stopThumbnailRefresh() {
        this.refreshThumbnailSubscription.unsubscribe();
    }

    refreshThumbnail() {
        this.targetsService.refreshTargetThumbnail(this.target);
    }

    cancel() {
        this.gotoTargets();
    }

    gotoTargets() {
        this.router.navigate([Constants.urls.targets]);
    }

    async deleteTarget() {
        await this.modalService.confirm(
            "DELETE",
            "TARGET",
            async () => {
                const id = this.target.id;
                const result = await this.targetsService.deleteTarget(this.target.target);
                if (result) {
                    this.mixpanelService.sendEvent("delete target", { id });
                    this.gotoTargets();
                } else {
                    return false;
                }
            },
            this.target.target.name,
            { warning: this.target.dynamic ? "DYNAMIC_PULL_DELETE_WARNING" : undefined }
        );
    }

    editTarget(name: string) {
        return urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "edit");
    }

    cloneTarget(name: string) {
        return this.router.navigate(urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "clone"));
    }

    config() {
        this.targetsService.targetConfigHelp(this.target);
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.target.target.active_mute ? "unmute" : "mute") + " target");
        this.targetsService.updateTarget(this.target.target, {
            muted: !this.target.target.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "target" + " until: " + date.toISOString());
        await this.targetsService.updateTarget(this.target.target, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.target.target.is_enabled
        };
        if (this.target.target.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "TARGET",
            async () => {
                const result = await this.targetsService.updateTarget(this.target.target, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " target", {
                        id: this.target.id
                    });
                    return true;
                } else {
                    return false;
                }
            },
            this.target.target.name
        );
    }

    canEdit(target: AnyTarget) {
        if (!target) return false;
        return this.sharedService.canEditZixiObject(target.target, this.resourceTags, this.userPermissions);
    }

    async switchChannel() {
        const result = await this.modalService.switchChannel(
            [this.target],
            this.target.adaptive ? "adaptive" : "delivery"
        );
        if (result) {
            this.mixpanelService.sendEvent("switch channel", {
                id: this.target.id
            });
            this.targetsService.refreshTarget(this.targetApiType, this.target.objId, true);
        }
    }

    public refreshTarget = () => {
        this.targetsService.refreshTarget(this.target.apiType, this.target.target.id, true);
    };

    refreshTargetPromise() {
        return firstValueFrom(this.targetsService.refreshTarget(this.target.apiType, this.target.target.id, true));
    }

    targetBackendType() {
        return this.targetsService.targetBackendType(this.target);
    }

    getDisasterRecoveryState() {
        const target = this.target.target;
        return this.targetsService.getDisasterRecoveryState(target);
    }

    async goToDR() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = [{ id: this.target.target.id, type: this.target.type }];

        modal.result.then(() => {
            this.refreshTarget();
            this.recoveryState = this.getDisasterRecoveryState();
        });
    }
}
